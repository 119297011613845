<script>
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'pfadaufgabenFunctions',
  data() {
    return {
      currentX: '',
      currentY: '',
      initialX: '',
      initialY: '',
      distance: 0,
      scrollDistance: 0,
      initialScrollTop: 0,
      stopY: true,
      dragItem: null,
      maxRight: '',
      maxLeft: '',
      hoveringItem: null,
      remainingPairAnswers: [],
      dragItemPosition: 'center',
      activeDragDirection: '',
      result: null,
      focusedAnswer: null,
      selectedAnswers: [],
      selectedListAnswers: [],
      inputText: '',
      leftAnswers: [],
      solvedAnswers: 0,
    };
  },

  computed: {
    selectedPlaceholder() {
      return this.$store.state.learning.selectedPlaceholder;
    },
    savingProgress() {
      return this.$store.state.learning.savingProgress;
    },
    review() {
      return this.$store.state.training.settings.review;
    },
  },

  watch: {
    inputText() {
      this.result = null;
      if (this.inputText !== '') {
        this.selectedAnswers = [this.inputText];
      }
    },

    maxAnswers() {
      this.generatePlaceholderAnswerStructure();
    },
  },

  methods: {

    pairDragStart(e) {
      if (!this.review) {
        this.result = null;
        this.initialScrollTop = window.pageYOffset;
        this.selectedAnswers = [];
        this.dragItem = e.srcElement.closest('#pairAnswer');
        this.dragItem.style.zIndex = 999;
        const pairAnswers = Array.from(this.$refs.pairAnswers.childNodes);
        pairAnswers.forEach((answer) => {
          if (answer?.id === 'pairAnswer' && answer !== this.dragItem) {
            this.remainingPairAnswers.push(answer);
          }
        });

        if (e.type === 'touchstart') {
          this.initialX = e.touches[0].clientX;
          this.initialY = e.touches[0].clientY;
        } else {
          this.initialX = e.clientX;
          this.initialY = e.clientY;
        }
      }
    },

    scroll(stepY) {
      const scrollY = window.pageYOffset;
      window.scrollTo(0, (scrollY + stepY));
      this.scrollDistance = scrollY - this.initialScrollTop;
      if (!this.stopY) {
        setTimeout(() => {
          this.scroll(stepY);
        }, 20);
      }
    },

    verticalMaxed() {
      return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
    },

    pairDrag(e) {
      if (this.dragItem && !this.review) {
        this.stopY = true;
        if (e.type === 'touchmove') {
          this.currentX = e.touches[0].clientX - this.initialX;
          this.currentY = e.touches[0].clientY - this.initialY;
        } else {
          this.currentX = e.clientX - this.initialX;
          this.currentY = e.clientY - this.initialY;
        }

        let clientY = 0;
        if (e.type === 'touchmove') {
          clientY = e.touches[0].clientY;
        } else {
          clientY = e.clientY;
        }

        if (clientY < (window.innerHeight / 5)) {
          this.stopY = false;
          this.scroll(-1);
        }

        if (clientY > (window.innerHeight - (window.innerHeight / 5)) && !this.verticalMaxed()) {
          this.stopY = false;
          this.scroll(1);
        }



        const cursorPositionX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
        const cursorPositionY = e.type === 'touchmove' ? e.touches[0].clientY : e.clientY;


        let hoveringAnswer = null;
        this.remainingPairAnswers.forEach((answer) => {
          const domRect = answer.getBoundingClientRect();
          if (cursorPositionY < domRect.bottom && cursorPositionX > domRect.left && cursorPositionY > domRect.top && cursorPositionX < domRect.right) {
            hoveringAnswer = answer;
          }
        });

        if (hoveringAnswer !== null) {
          if (this.hoveringItem !== hoveringAnswer) {
            this.hoveringItem = hoveringAnswer;
            this.iosVibrate(0);
            this.focusedAnswer = hoveringAnswer.dataset.answer;
          }
        } else {
          this.hoveringItem = null;
        }
        anime.set(this.dragItem, {
          translateX: this.currentX,
          translateY: this.currentY + this.scrollDistance,
        });
      }
    },

    pairDragEnd() {
      if (!this.review) {
        this.stopY = true;
        this.scrollDistance = 0;
        this.initialScrollTop = 0;
        if (this.hoveringItem !== null) {
          this.selectedAnswers.push(this.hoveringItem.dataset.answer);
          this.selectedAnswers.push(this.dragItem.dataset.answer);
          this.checkBegriffspaare();
        }
        this.dragItem.style.zIndex = 0;
        anime({
          targets: this.dragItem,
          easing: 'easeInOutCubic',
          duration: 250,
          translateX: 0,
          translateY: 0,
        });
        this.remainingPairAnswers = [];
        this.hoveringItem = null;
        this.focusedAnswer = null;
        this.dragItem = null;
        this.currentX = '';
        this.currentY = '';
        this.initialX = '';
        this.initialY = '';
      }
    },

    moveLeft(e) {
      if (!this.review) {
        this.result = null;
        this.dragItem = e.srcElement.closest('#answer');
        this.focusedAnswer = this.dragItem.getAttribute('data-answer');
        if (this.dragItem.dataset.maxleft === '0') {
          const listBound = document.getElementById('liste').getBoundingClientRect();
          const elementBound = this.dragItem.getBoundingClientRect();
          this.dragItem.dataset.maxright = listBound.right - elementBound.right;
          this.dragItem.dataset.maxleft = listBound.left - elementBound.left;
          this.maxLeft = listBound.left - elementBound.left;
          this.maxRight = listBound.right - elementBound.right;
        } else {
          this.maxLeft = this.dragItem.dataset.maxleft;
          this.maxRight = this.dragItem.dataset.maxright;
        }
        this.checkListItemPresence();
        this.selectedAnswers.push(this.focusedAnswer);
        this.selectedListAnswers.push({
          answer: this.focusedAnswer,
          list: 1,
        });
        anime({
          targets: this.dragItem,
          easing: 'easeInOutCubic',
          duration: 250,
          translateX: this.maxLeft,
        });
        this.dragItem = null;
      }
    },

    moveRight(e) {
      if (!this.review) {
        this.result = null;
        this.dragItem = e.srcElement.closest('#answer');
        this.focusedAnswer = this.dragItem.getAttribute('data-answer');
        if (this.dragItem.dataset.maxleft === '0') {
          const listBound = document.getElementById('liste').getBoundingClientRect();
          const elementBound = this.dragItem.getBoundingClientRect();
          this.dragItem.dataset.maxright = listBound.right - elementBound.right;
          this.dragItem.dataset.maxleft = listBound.left - elementBound.left;
          this.maxLeft = listBound.left - elementBound.left;
          this.maxRight = listBound.right - elementBound.right;
        } else {
          this.maxLeft = this.dragItem.dataset.maxleft;
          this.maxRight = this.dragItem.dataset.maxright;
        }
        this.checkListItemPresence();
        this.selectedAnswers.push(this.focusedAnswer);
        this.selectedListAnswers.push({
          answer: this.focusedAnswer,
          list: 2,
        });
        anime({
          targets: this.dragItem,
          easing: 'easeInOutCubic',
          duration: 250,
          translateX: this.maxRight,
        });
        this.dragItem.dataset.moved = this.maxRight;
        this.dragItem = null;
      }
    },

    answerDragStart(e) {
      if (!this.review) {
        this.result = null;
        this.dragItem = e.srcElement.closest('#answer');
        this.focusedAnswer = this.dragItem.getAttribute('data-answer');
        if (this.dragItem.dataset.maxleft === '0') {
          const listBound = document.getElementById('liste').getBoundingClientRect();
          const elementBound = this.dragItem.getBoundingClientRect();
          this.dragItem.dataset.maxright = listBound.right - elementBound.right;
          this.dragItem.dataset.maxleft = listBound.left - elementBound.left;
          this.maxLeft = listBound.left - elementBound.left;
          this.maxRight = listBound.right - elementBound.right;
        } else {
          this.maxLeft = this.dragItem.dataset.maxleft;
          this.maxRight = this.dragItem.dataset.maxright;
        }

        if (e.type === 'touchstart') {
          this.initialX = e.touches[0].clientX;
          this.initialY = e.touches[0].clientY;
        } else {
          this.initialX = e.clientX;
          this.initialY = e.clientY;
        }
      }
    },

    answerDrag(e) {
      if (this.dragItem && !this.review) {
        if (e.type === 'touchmove') {
          this.currentX = e.touches[0].clientX - this.initialX;
          this.currentY = e.touches[0].clientY - this.initialY;
        } else {
          this.currentX = e.clientX - this.initialX;
          this.currentY = e.clientY - this.initialY;
        }

        this.distance = Math.abs(this.currentX) - Math.abs(this.currentY);

        if (Math.abs(this.distance) > 10 && this.activeDragDirection === '') {
          if (Math.abs(this.currentX) > Math.abs(this.currentY)) {
            this.activeDragDirection = 'horizontal';
          } else {
            this.activeDragDirection = 'vertical';
          }
        }

        const moved = Number(this.dragItem.dataset.moved);
        const moveTo = moved + this.currentX;

        if (this.activeDragDirection === 'horizontal' && moveTo < this.maxRight && moveTo > this.maxLeft) {
          e.preventDefault();
          anime.set(this.dragItem, {
            translateX: moveTo,
          });

          if (moveTo > this.maxRight / 2 && this.dragItemPosition !== 'right') {
            this.iosVibrate(0);
            this.dragItemPosition = 'right';
          }

          if (moveTo > this.maxLeft / 2 && moveTo < this.maxRight / 2 && this.dragItemPosition !== 'center') {
            this.iosVibrate(0);
            this.dragItemPosition = 'center';
          }

          if (moveTo < this.maxLeft / 2 && this.dragItemPosition !== 'left') {
            this.iosVibrate(0);
            this.dragItemPosition = 'left';
          }
        }
      }
    },

    answerDragEnd() {
      if (!this.review) {
        if (this.dragItemPosition === 'right') {
        // bounce right
          this.checkListItemPresence();
          this.selectedAnswers.push(this.focusedAnswer);
          this.selectedListAnswers.push({
            answer: this.focusedAnswer,
            list: 2,
          });
          anime({
            targets: this.dragItem,
            easing: 'easeInOutCubic',
            duration: 250,
            translateX: this.maxRight,
          });
          this.dragItem.dataset.moved = this.maxRight;
        }

        if (this.dragItemPosition === 'left') {
          // bounce left
          this.checkListItemPresence();
          this.selectedAnswers.push(this.focusedAnswer);
          this.selectedListAnswers.push({
            answer: this.focusedAnswer,
            list: 1,
          });
          anime({
            targets: this.dragItem,
            easing: 'easeInOutCubic',
            duration: 250,
            translateX: this.maxLeft,
          });
          this.dragItem.dataset.moved = this.maxLeft;
        }

        if (this.dragItemPosition === 'center' && this.distance > 1) {
          this.checkListItemPresence();
          anime({
            targets: this.dragItem,
            easing: 'easeInOutCubic',
            duration: 250,
            translateX: 0,
          });
          this.dragItem.dataset.moved = 0;
        }

        this.dragItemPosition = 'center';
        this.activeDragDirection = '';
        this.currentX = '';
        this.currentY = '';
        this.initialX = '';
        this.initialY = '';
        this.distance = 0;
        this.dragItem = null;
      }
    },

    loadListAnswers(selectedListAnswers) {
      this.$nextTick(() => {
        const answerNodes = Array.from(this.$refs.answers.childNodes);
        const listBound = document.getElementById('liste').getBoundingClientRect();
        answerNodes.forEach((answer, index) => {
          if (answer?.id === 'answer') {
            const elementBound = answer.getBoundingClientRect();
            answerNodes[index].dataset.maxright = listBound.right - elementBound.right;
            answerNodes[index].dataset.maxleft = listBound.left - elementBound.left;
            this.maxLeft = listBound.left - elementBound.left;
            this.maxRight = listBound.right - elementBound.right;
            selectedListAnswers.forEach((listAnswer, listIndex) => {
              if (listAnswer.answer === answer.dataset.answer) {
                anime({
                  targets: answerNodes[index],
                  easing: 'easeInOutCubic',
                  duration: 250,
                  translateX: selectedListAnswers[listIndex]?.list === 1 ? this.maxLeft : this.maxRight,
                });
              }
            });
          }
        });
      });
    },

    checkListItemPresence() {
      const listIndex = this.selectedListAnswers.findIndex((answers) => answers.answer === this.focusedAnswer);
      const answerIndex = this.selectedAnswers.indexOf(this.focusedAnswer);
      if (listIndex !== -1) {
        this.selectedListAnswers.splice(listIndex, 1);
      }
      if (answerIndex !== -1) {
        this.selectedAnswers.splice(answerIndex, 1);
      }
    },

    validateStringMatch({ answers, validate }) {
      if (answers === validate) {
        this.success();
      } else {
        this.failure();
      }
    },

    validateVokabelMatch({ answers, validate }) {
      const splitted = validate.split(';');
      let matches = 0;
      splitted.forEach((item) => {
        if (item.trim() === answers) {
          matches += 1;
        }
      });
      if (matches > 0) {
        this.success();
      } else {
        this.failure();
      }
    },

    validatePairMatch({ answers, validate }) {
      let match = false;
      validate.forEach((pair) => {
        if ((pair.begriff1.trim() === answers[0].trim() || pair.begriff1.trim() === answers[1].trim()) && (pair.begriff2.trim() === answers[0].trim() || pair.begriff2.trim() === answers[1].trim())) {
          match = true;
        }
      });
      if (match) {
        this.solvedAnswers += 1;
        if (this.solvedAnswers >= validate.length) {
          this.success();
        } else {
          this.iosVibrate(3);
          this.result = 'continue';
          this.leftAnswers = this.possibleAnswers.filter((item) => item !== answers[0]);
          this.leftAnswers = this.possibleAnswers.filter((item) => item !== answers[1]);
        }
      } else {
        this.failure();
      }
    },

    openListPicker() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', 'listPicker');
    },


    splitPairAnswers(answers) {
      const splitAnswers = [];
      answers.forEach((answer) => {
        splitAnswers.push(answer.trim());
        /* const split = answer.split('+');
        split.forEach((splitAnswer) => {
          splitAnswers.push(splitAnswer.trim());
        }); */
      });
      return splitAnswers;
    },

    addAnswer(answer) {
      if (this.result === 'continue') {
        this.hidePairs();
        this.selectedAnswers.push(answer);
      } else {
        this.iosVibrate(0);
        const index = this.selectedAnswers.indexOf(answer);
        if (index === -1) {
          if (this.taskType === 'ComponentAufgabenMcFrage' && this.maxAnswers <= this.selectedAnswers.length) {
            //
          } else if (this.taskType !== 'ComponentAufgabenBegriffspaare' && this.taskType !== 'ComponentAufgabenListenzuordnung') {
            this.selectedAnswers.splice(this.selectedPlaceholder, 1, answer);
          }
        } else if (index !== -1 && (this.taskType === 'ComponentAufgabenMcFrage')) {
          this.selectedAnswers.splice(index, 1);
          this.result = null;
        } else if (index !== -1 && this.taskType === 'ComponentAufgabenListenzuordnung') {
          this.selectedAnswers.splice(index, 1);
          this.result = null;
          const listIndex = this.selectedListAnswers.findIndex((answers) => answers.answer === answer);
          this.selectedListAnswers.splice(listIndex, 1);
        }
      }
      this.$store.commit('setSelectedPlaceholder', this.getNextPlaceholder());
    },

    getNextPlaceholder() {
      let nextIndex = 0;
      let found = false;
      this.selectedAnswers.some((answer, index) => {
        if (answer === 'OPTION' && index > this.selectedPlaceholder) {
          nextIndex = index;
          found = true;
          return true;
        }
        return false;
      });
      if (!found) {
        this.selectedAnswers.some((answer, index) => {
          if (answer === 'OPTION') {
            nextIndex = index;
            found = true;
            return true;
          }
          return false;
        });
      }
      if (!found) {
        return this.maxAnswers - 1;
      }
      return nextIndex;
    },

    removeLastAnswer() {
      this.iosVibrate(0);
      this.result = null;
      this.inputText = '';
      if (this.taskType !== 'ComponentAufgabenBegriffspaare' && this.taskType !== 'ComponentAufgabenListenzuordnung' && this.taskType !== 'ComponentAufgabenMcFrage') {
        let newIndex = this.selectedPlaceholder;
        if (this.selectedAnswers[newIndex] === 'OPTION') {
          newIndex -= 1;
        }
        if (newIndex < 0) {
          newIndex = 0;
        }
        this.selectedAnswers.splice(newIndex, 1, 'OPTION');
        this.$store.commit('setSelectedPlaceholder', newIndex);
      } else {
        this.selectedAnswers.pop();
      }
    },

    refresh() {
      this.iosVibrate(0);
      this.result = null;
      this.inputText = '';
      this.selectedAnswers = [];
      this.selectedListAnswers = [];
      this.focusedAnswer = null;
      if (this.taskType === 'ComponentAufgabenListenzuordnung') {
        const children = this.$refs?.answers?.childNodes;
        if (children) {
          const answers = Array.from(children);
          answers.forEach((answer, index) => {
            if (answer?.id === 'answer') {
              answers[index].dataset.maxleft = 0;
              answers[index].dataset.maxRight = 0;
              answers[index].dataset.moved = 0;
              anime({
                targets: answers[index],
                easing: 'easeInOutCubic',
                duration: 250,
                translateX: 0,
              });
            }
          });
        }
      }
      this.leftAnswers = [];
      this.solvedAnswers = 0;
      this.$store.commit('setSelectedPlaceholder', 0);
      this.generatePlaceholderAnswerStructure();
    },

    hidePairs() {
      this.selectedAnswers = [];
      this.result = null;
    },

  },
};
</script>
